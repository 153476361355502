import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ModernImg from "../../components/ModernImg"
import SinglePageHeader from "../../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Portfolio() {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query PortfolioPageQuery {
      portfolio: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "portfolio" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              logo
              ipo_completed
              ipo_price
              ipo_date
              date
              templateKey
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const companies = data.portfolio.edges.map(edge => {
    const p = edge.node
    return (
      <div key={p.id}>
        <div className="overlay"></div>
        <img src={p.frontmatter.logo} alt={p.frontmatter.title} />
        <strong style={{ textAlign: "center" }}>{p.frontmatter.title}</strong>
        <span className="summary">{p.frontmatter.description}</span>
      </div>
    )
  })

  const ipoCompleted = data.portfolio.edges
    .filter(edge => edge.node.frontmatter.ipo_completed)
    .map(edge => {
      const p = edge.node
      return (
        <div key={p.id}>
          <div className="overlay"></div>
          <img src={p.frontmatter.logo} alt={p.frontmatter.title} />
          <strong style={{ textAlign: "center" }}>{p.frontmatter.title}</strong>
          {/* <div className="ipo-summary"> */}
          {/* <div>{p.frontmatter.ipo_price}</div> */}
          {/* <div>{new Date(p.frontmatter.ipo_date).toDateString()}</div> */}
          {/* </div> */}
        </div>
      )
    })

  return (
    <SinglePageHeader
      title={t("private-equity")}
      bg="/assets/img/portfolio-header.jpg"
    >
      <div className="container pd-top-100 pd-bottom-100">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title section-title-2 text-center">
              <h6 className="sub-title">{t("private-equity")}</h6>
              <h2 className="title">{t("investee-companies")}</h2>
            </div>
          </div>
        </div>
        <div className="portfolio-grid">{companies}</div>
      </div>
      <section className="ich-container bg-grey" style={{ maxWidth: "100%" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h6 className="sub-title">{t("private-equity")}</h6>
                <h2 className="title">{t("ipo-completed-at-a-glance")}</h2>
              </div>
            </div>
          </div>
          <div className="portfolio-grid">{ipoCompleted}</div>
        </div>
      </section>
      <div className="ich-container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title section-title-2 text-center">
              <h6 className="sub-title">{t("private-equity")}</h6>
              <h2 className="title">{t("ich-gemini-asia-growth-fund")}</h2>
            </div>
          </div>
        </div>
        <div className="row pd-bottom-100">
          <div className="col-lg-3 col-xs-12">
            <p>
              <strong>{t("date-of-inception")}: </strong>{" "}
              {t("date-of-inception-a")}
            </p>
            <p>
              <strong>{t("status")}: </strong> {t("status-a")}
            </p>
            <p>
              <strong>{t("fund-size")}: </strong> {t("fund-size-a")}
            </p>
            <p>
              <strong>{t("no-of-invested-companies")}: </strong>
              <br />
              {t("no-of-invested-companies-a")}
            </p>
            <p>
              <strong>{t("no-of-divested-companies")}:</strong>
              <br />
              {t("no-of-divested-companies-a")}
            </p>
            <p>
              <strong>{t("investment-period")}: </strong> <br />{" "}
              {t("investment-period-a")}
            </p>
            <p>
              <strong>{t("exit-strategy")}: </strong>
              <br /> {t("exit-strategy-a")}
            </p>
          </div>
          <div className="col-lg-9 col-xs-12">
            <ModernImg
              src="/assets/img/growth.png"
              alt="ich gemini asia growth"
              style={{ marginTop: -5 }}
            />
          </div>
        </div>
      </div>
    </SinglePageHeader>
  )
}
